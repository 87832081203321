import PropTypes from 'prop-types'
import Icon from '../Icon'

const ActionButtonFile = ({icon, className, onClick, title, format}) => {
  return (
    <label onClick={onClick} className={`action-button ${className}`}>
      <input type="file" accept={format} title={title} />
      <Icon className="icon" svg={icon} color="var(--text-color)" />
    </label>
  )
}

ActionButtonFile.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  icon: PropTypes.node.isRequired,
  title: PropTypes.string,
}

ActionButtonFile.defaultProps = {
  className: '',
  title: '',
}

export default ActionButtonFile
