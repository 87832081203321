import PropTypes from 'prop-types'

const FormInput = props => {
  const {
    label,
    type,
    placeholder,
    id,
    name,
    className,
    isRequired,
    isDisabled,
    errorMessage,
    register,
    ...rest
  } = props

  return (
    <div className={`form-item ${className}`}>
      <input
        className="input"
        disabled={isDisabled}
        required={isRequired}
        id={id}
        type={type}
        placeholder={placeholder}
        name={name}
        {...register}
        {...rest}
      />
      {label && <label htmlFor={id}>{label}</label>}
      {errorMessage && <span className='error-msg'>{errorMessage}</span>}
    </div>
  )
}

FormInput.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
}

FormInput.defaultProps = {
  type: 'text',
  placeholder: '',
  id: '',
  className: '',
  isRequired: false,
  isDisabled: false,
}

export default FormInput
