import {useEffect, useState} from 'react'
import PropTypes from 'prop-types'

const Animation = ({show, animationIn, animationOut, children, className}) => {
  const [shouldRender, setRender] = useState(show)

  useEffect(() => {
    if (show) setRender(true)
  }, [show])

  const onAnimationEnd = () => {
    if (!show) setRender(false)
  }

  return (
    shouldRender && (
      <div
        className={className}
        style={{
          animation: `${
            show ? animationIn : animationOut
          } var(--min-transition-time) forwards`,
        }}
        onAnimationEnd={onAnimationEnd}
      >
        {children}
      </div>
    )
  )
}

Animation.propTypes = {
  animationIn: PropTypes.string.isRequired,
  animationOut: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  show: PropTypes.bool.isRequired,
  className: PropTypes.string,
}

Animation.defaultProps = {
  className: '',
}

export default Animation
