import {useState, useEffect} from 'react'

export const useKeyDownEsc = () => {
  const [keyDownEsc, setKeyDownEsc] = useState(false)

  const handleKeyDown = e => {
    if (e.keyCode === 27) {
      setKeyDownEsc(true)
    }
  }

  const resetKeyDown = () => {
    setKeyDownEsc(false)
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    // Se debe reiniciar el keydown, porque al activarse quedaría siempre en el mismo estado
    document.addEventListener('click', resetKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('click', resetKeyDown)
    }
  }, [keyDownEsc])

  return keyDownEsc
}
