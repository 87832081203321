import { useContext, useEffect } from 'react'
import {Switch, BrowserRouter, Redirect} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import routes from './routes'
import Home from '../pages/Home'
import Login from '../pages/Login'
import RecoverPassword from '../pages/RecoverPassword'
import OTP from '../pages/OTP'
import Profile from '../pages/Profile'
import ResetPassword from '../pages/ResetPassword'
import Public from '../config/Routes/Public'
import Private from '../config/Routes/Private'
import Alert from '../ui-kit/components/atoms/Alert'
import { getCacheIdentificationTypes } from '../services/cache'
import { GlobalContext } from '../ui-kit/helpers/context'
import {actions as notificationActions} from '../redux/ducks/notification'

const App = () => {
  const dispatch = useDispatch()
  const notification = useSelector(store => store.notificationReducer)
  const {setIdentificationTypes} = useContext(GlobalContext)

  useEffect(() => {
    getCacheIdentificationTypes().then(data => {
      setIdentificationTypes(data)
    })
  }, []) // eslint-disable-line

  const handleClose = () => dispatch(notificationActions.clear())

  return (
    <>
      <Alert
        message={notification.message}
        severity={notification.severity}
        onClose={handleClose}
      />

      <BrowserRouter>
        <Switch>
          <Public path={routes.login} exact>
            <Login />
          </Public>

          <Public path={routes.recover} exact>
            <RecoverPassword />
          </Public>

          <Public path={routes.otp} exact>
            <OTP />
          </Public>

          <Public path={routes.reset} exact>
            <ResetPassword />
          </Public>

          <Private path={routes.profile} exact>
            <Profile />
          </Private>

          <Private path={routes.home} exact>
            <Home />
          </Private>

          <Redirect to={routes.login} />
        </Switch>
      </BrowserRouter>
    </>
  )
}

export default App
