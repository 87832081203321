import {getURLImage} from '../../helpers/utils'
import DefaultAvatarMan from '../../static/images/avatars/stock-1.svg'
import DefaultAvatarWomen from '../../static/images/avatars/stock-2.svg'

const DefaultAvatar = {
  MASCULINO: DefaultAvatarMan,
  FEMENINO: DefaultAvatarWomen,
}

const UserAvatar = ({className, picture = null, size, gender = 'MASCULINO'}) => {
  return (
    <div className={`user-avatar ${className} ${size}`}>
      <img
        src={picture ? getURLImage(picture) : DefaultAvatar[gender]}
        alt="Avatar del usuario"
      />
    </div>
  )
}

export default UserAvatar
