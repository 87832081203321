import React, {useContext} from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import Icon from '../Icon'
import {FloatContainerContext} from '../../../helpers/context'

const NavItem = ({text, icon, url, className, onClick, isInDropdown}) => {
  const {setActive} = useContext(FloatContainerContext)

  const clickFunction = () => {
    onClick()
    if (isInDropdown) setActive(false)
  }

  return (
    <li onClick={clickFunction}>
      <Link to={url} className={`nav-item ${className}`}>
        <Icon className="icon" svg={icon} size="1rem" />
        <span className="text">{text}</span>
      </Link>
    </li>
  )
}

NavItem.propTypes = {
  url: PropTypes.string,
  onClick: PropTypes.func,
}

NavItem.defaultProps = {
  url: '',
  onClick: () => {},
}

export default NavItem
