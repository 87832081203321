import Loader from '../atoms/Loader'

const FullPageLoader = () => {
  return (
    <div className="full-page-loader s-w-full">
      <div className="loader">
        <Loader />
      </div>
    </div>
  )
}

export default FullPageLoader
