import UserAvatar from '../atoms/UserAvatar'

const UserItem = ({picture = null, content = "some content", subcontent = "some subcontent", gender}) => {
  return (
    <article className="user-item">
      <UserAvatar picture={picture} gender={gender}/>
      <div>
        <p>{content}</p>
        <p className="sub-content">{subcontent}</p>
      </div>
    </article>
  )
}

export default UserItem
