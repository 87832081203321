import PropTypes from 'prop-types'
import UserAvatar from '../../ui-kit/components/atoms/UserAvatar'

const RespondForm = ({isAnswer, text}) => {
  return (
    <form className={`respond-form ${isAnswer && 'answer'}`}>
      <div className="input-container">
        <UserAvatar className="big" />
        <div contentEditable data-placeholder={text} className="input-field" />
      </div>
      <button className="button small">Comentar</button>
    </form>
  )
}

RespondForm.propTypes = {
  text: PropTypes.string,
}

RespondForm.defaultProps = {
  text: 'Agrega un comentario',
}

export default RespondForm
