import axios from 'axios'
import store from '../redux/store'
import {NOTIFICATION} from '../redux/ducks/notification'
import {closeSession} from '../application/auth'
import {isEmpty, isString} from '../ui-kit/helpers/utils'

export class Request {
  constructor(token = null) {
    // create an axios instance
    this.axios = axios.create({baseURL: process.env.REACT_APP_API_URL})
    this.hasAuthorization = false

    if (token) {
      this.hasAuthorization = true
      this.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    }

    // returns only the data or specific error of Go API
    this.axios.defaults.transformResponse = [
      function (data) {
        if (isString(data)) {
          try {
            if (isEmpty(data)) return data

            data = JSON.parse(data)
            if (data.errors) {
              return data.errors[0].message
            }

            return data.data
          } catch (e) {
            console.error('Server backend is off', e)
          }
        }

        return data
      },
    ]
  }

  async get(route, hasCatch = false) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.axios.get(route)
        resolve(response.data)
      } catch (error) {
        if (hasCatch) {
          reject(error.response.data)
          return
        }

        this.handleError(route, error)
      }
    })
  }

  async getWithResponseObject(route, hasCatch = false) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.axios.get(route)
        resolve(response)
      } catch (error) {
        if (hasCatch) {
          reject(error.response.data)
          return
        }

        this.handleError(route, error)
      }
    })
  }

  async getFile(route, hasCatch = false) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.axios.get(route, {
          // Los datos de esta petición se interpretan como datos binarios.
          responseType: 'blob',
        })

       resolve({data: response.data, headers: response.headers})
      } catch (error) {
        let data = await error.response.data.text();
        data = JSON.parse(data)
        error.response.data = data.errors[0].message

        if (hasCatch) {
          reject(error.response.data)
          return
        }

        this.handleError(route, error)
      }
    })
  }

  async generateFile(route, body, hasCatch = false) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.axios.put(route, body, {
          // Los datos de esta petición se interpretan como datos binarios.
          responseType: 'blob',
        })

        resolve({data: response.data, headers: response.headers})
      } catch (error) {
        let data = await error.response.data.text();
        data = JSON.parse(data)
        error.response.data = data.errors[0].message

        if (hasCatch) {
          reject(error.response.data)
          return
        }

        this.handleError(route, error)
      }
    })
  }

  async post(route, body, hasCatch = false) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.axios.post(route, body)
        resolve(response.data)
      } catch (error) {
        if (hasCatch) {
          reject(error.response.data)
          return 
        }

        this.handleError(route, error)
      }
    })
  }

  async put(route, body, hasCatch = false) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.axios.put(route, body)
        resolve(response.data)
      } catch (error) {
        if (hasCatch) {
          reject(error.response.data)
          return
        }

        this.handleError(route, error)
      }
    })
  }

  async delete(route, hasCatch = false) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.axios.delete(route)
        resolve(response.data)
      } catch (error) {
        if (hasCatch) {
          reject(error.response.data)
          return 
        }

        this.handleError(route, error)
      }
    })
  }

  handleError = (route, error) => {
    if (
      this.hasAuthorization &&
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      closeSession()
      return
    }

    if (error.response) {
      store.dispatch({
        type: NOTIFICATION,
        payload: {
          message: error.response.data,
          severity: 'error',
        },
      })
      return
    }

    if (error.request) {
      console.log('el servidor del API esta apagado')
      return
    }

    console.error(route, error.message)
    return
  }
}

export class RequestWithAuthorization extends Request {
  constructor() {
    super(localStorage.getItem(process.env.REACT_APP_TOKEN_KEY))
  }
}
