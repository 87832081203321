import {useEffect, useRef} from 'react'
import {BELL_ICON} from '../../helpers/icons/bell'
import {CROSS_ICON} from '../../helpers/icons/cross'
import {isUndefined} from '../../helpers/utils'
import Icon from './Icon'

const Alert = ({onClose, message, severity = 'info'}) => {
  const refAlert = useRef()

  useEffect(() => {
    const handleClickOutside = event => {
      if (refAlert.current && !refAlert.current.contains(event.target)) {
        onClose()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [onClose])

  if (isUndefined(message)) return null

  return (
    <div className={`alert ${severity}`} ref={refAlert}>
      <div className="message">
        <Icon className="icon" svg={BELL_ICON} />
        <div>
          {message.split('\n').map((i, key) => {
            return <div key={key}>{i}</div>
          })}
        </div>
      </div>
      <Icon className="icon close-button" svg={CROSS_ICON} onClick={onClose} />
    </div>
  )
}

export default Alert
