import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import DropdownSelect from '../../ui-kit/components/atoms/DropdownSelect'
import DropdownItem from '../../ui-kit/components/atoms/items/DropdownItem'
import {Document, Page} from 'react-pdf/dist/umd/entry.webpack'
import { isEqual, isNull } from '../../ui-kit/helpers/utils'
import PayslipOptions from '../molecules/PayslipOptions'
import LaborCertificateOptions from '../molecules/LaborCertificateOptions'
import Form220Options from '../molecules/Form220Options'
import {selectors as userSelectors} from '../../redux/ducks/user'
import {selectors as employerSelectors} from '../../redux/ducks/employer'
import useObjectURL from '../../ui-kit/hooks/useObjectURL'
import DocumentEmpty from './empty/DocumentEmpty'
import DocumentLoader from './DocumentLoader'

const OPTION_PAYSLIP = 'PAYSLIP'
const OPTION_LABOR_CERTIFICATE = 'LABOR_CERTIFICATE'
const OPTION_FORM_2020 = 'FORM_220'

const OPTIONS = [
  {key: OPTION_PAYSLIP, name: "Comprobante de pago"},
  {key: OPTION_LABOR_CERTIFICATE, name: "Certificado Laboral"},
  // {key: OPTION_FORM_2020, name: "Certificado Ingresos y retenciones"},
]

const DocumentsSection = () => {
  const user = useSelector(userSelectors.get)
  const employer = useSelector(employerSelectors.get)
  const contractID = user?.contracts[0]?.contract?.id

  const [option, setOption] = useState(OPTION_PAYSLIP)
  const [numPages, setNumPages] = useState(0)
  const [pageNumber, setPageNumber] = useState(1)

  const documentRef = useRef(null)
  const downloadLinkReport = useRef(null)
  const [documentWidth, setDocumentWidth] = useState(0);
  const [documentHeight, setDocumentHeight] = useState(0);
  const {objectURL, setObject} = useObjectURL(null)

  useEffect(() => {
    const handleResize = () => {
      if (documentRef.current) {
        const width = documentRef.current.offsetWidth
        const height = width * 1.4142 // Calculate the height for a sheet A4 width aspect ratio
        
        setDocumentWidth(width)
        setDocumentHeight(height)
      }
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [])

  function handleChangeOption(opt) {
    setOption(opt)
    // we set the page zero to define the option file change
    setNumPages(0)
  }

  function onDocumentLoadSuccess({numPages}) {
    setNumPages(numPages)
  }

  function handleDownloadFile() {
    downloadLinkReport.current.click()
  }

  return (
    <>
      <section className="documents-section n-container no-padding">
        <header className="header">
          <DropdownSelect
            selected={OPTIONS.find(o => isEqual(option, o.key)).name}
          >
            {OPTIONS.map(o => (
              <DropdownItem
                key={o.key}
                onClick={() => handleChangeOption(o.key)}
                isActive={isEqual(option, o.key)}
                text={o.name}
              />
            ))}
          </DropdownSelect>

          <div className='header-options'>
              {isEqual(option, OPTION_PAYSLIP) && (
                <PayslipOptions
                  hasFile={!isEqual(0, numPages)} 
                  onDownload={handleDownloadFile}
                  setFile={setObject}
                  contractID={contractID}
                />
              )}

              {isEqual(option, OPTION_LABOR_CERTIFICATE) && (
                <LaborCertificateOptions 
                  hasFile={!isEqual(0, numPages)} 
                  onDownload={handleDownloadFile}
                  setFile={setObject}
                  employerID={employer.id}
                />
              )}

              {isEqual(option, OPTION_FORM_2020) && <Form220Options/>}
          </div>
        </header>
        <div className="content">
          <Document 
            inputRef={documentRef}
            file={objectURL} 
            onLoadSuccess={onDocumentLoadSuccess} 
            className="pdf-viewer"
            loading={<DocumentLoader width={documentWidth} height={documentHeight}/>}
            noData={<DocumentEmpty width={documentWidth} height={documentHeight}/>}
            >
            <Page pageNumber={pageNumber} className="pdf-page" width={documentWidth} />
          </Document>
        </div>
      </section>

      <a
        ref={downloadLinkReport}
        href={objectURL}
        download={OPTIONS.find(o => isEqual(option, o.key)).name}
        className="hidden"
      >
        Descargar Reporte
      </a>
    </>
  )
}

export default DocumentsSection
