const routes = {
  login: '/login',
  recover: '/recuperar',
  otp: '/otp',
  reset: '/restablecer',
  home: '/',
  profile: '/perfil',
}

export default routes
