import React, {useContext} from 'react'
import PropTypes from 'prop-types'
import {TabsWrapperContext} from '../../helpers/context'

const PanelTemplate = ({index, children, className, onlyHidden}) => {
  const {tabActive} = useContext(TabsWrapperContext)
  const isActive = tabActive === index
  return isActive || onlyHidden ? (
    <div
      className={`panel-template ${className} ${
        onlyHidden && !isActive ? 'hidden' : 'show'
      }`}
    >
      {children}
    </div>
  ) : null
}

PanelTemplate.propTypes = {
  index: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onlyHidden: PropTypes.bool,
}

PanelTemplate.defaultProps = {
  className: '',
  onlyHidden: false,
}

export default PanelTemplate
