import Icon from '../../ui-kit/components/atoms/Icon'
import {CAKE_ICON} from '../../ui-kit/helpers/icons/cake'
import UserItem from '../../ui-kit/components/molecules/UserItem'
import { capitalize } from '../../ui-kit/helpers/string-format'
import { getMonthName } from '../../helpers/dayjs'

const BirthdaySection = ({data}) => {  
  return (
    <section className="aside-section n-container">
      <h2 className="title title-base">
        <Icon size="20px" svg={CAKE_ICON} /> Cumpleaños
      </h2>
      <div className="list">
        {Array.isArray(data) && data.map(e => (
          <UserItem 
            key={e.id} 
            content={capitalize([e?.first_name,e?.last_name].join(' '))} 
            subcontent={`🎉 ${e.birthday} ${capitalize(getMonthName(new Date().getMonth() + 1))}`} 
            picture={e.thumbnail}
            gender={e.gender}
          />
        ))}
      </div>
    </section>
  )
}

export default BirthdaySection
