import {useState} from 'react'
import {useDispatch} from 'react-redux'
import {set, useForm} from 'react-hook-form'
import WelcomeContainer from '../../wrappers/WelcomeContainer'
import {Link, useHistory, useLocation} from 'react-router-dom'
import PasswordInput from '../../molecules/inputs/PasswordInput'
import routes from '../../../config/routes'
import {isEqual} from '../../../ui-kit/helpers/utils'
import {notifyError, notifySuccessful} from '../../../services/notification'
import {changePassword, forgotPassword} from '../../../services/user'

const NewPasswordForm = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {search} = useLocation()

  const [isLoading, setIsLoading] = useState(false)
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm()

  const submit = data => {
    if (!isEqual(data.password_first, data.password_second)) {
      dispatch(notifyError('Las contraseñas no son iguales'))
      return
    }

    const query = new URLSearchParams(search)
    const payload = {
      identification_type_id: Number(query.get('t')),
      identification_number: query.get('n'),
      otp: query.get('otp'),
      password: data.password_first,
    }

    setIsLoading(true)
    changePassword(payload)
      .then(resp => {
        setIsLoading(false)
        dispatch(notifySuccessful(resp))
        history.push(routes.login)
      })
      .catch(err => {
        setIsLoading(false)
        dispatch(notifyError(err))
      })
  }

  const resendOTP = () => {
    if (isLoading) return

    const query = new URLSearchParams(search)

    const payload = {
      identification_number: query.get('n'),
      identification_type_id: Number(query.get('t')),
    }

    setIsLoading(true)

    forgotPassword(payload)
      .then(response => {
        setIsLoading(false)
        dispatch(notifySuccessful(response))

        query.delete('otp')
        history.push({
          pathname: routes.otp,
          search: `?${query.toString()}`,
        })
      })
      .catch(err => {
        setIsLoading(false)
        dispatch(notifyError(err))
      })
  }

  return (
    <WelcomeContainer
      title="Nueva contraseña"
      subtitle="Ingresa tú nueva contraseña para actualizarla."
    >
      <form className="form" onSubmit={handleSubmit(submit)}>
        <PasswordInput
          label="Nueva contraseña"
          id="password_first"
          register={register('password_first', passwordValidations)}
          errorMessage={errors?.password_first?.message}
        />
        <div className="input-password">
          <PasswordInput
            label="Repite la nueva contraseña"
            id="password_second"
            register={register('password_second', passwordValidations)}
            errorMessage={errors?.password_second?.message}
          />

          <div className="right">
            <span className="link" onClick={resendOTP}>
              Solicita un nuevo código
            </span>
          </div>
        </div>
        <button type="submit" className="button" disabled={isLoading}>
          Actualizar contraseña
        </button>
      </form>
    </WelcomeContainer>
  )
}

const passwordValidations = {
  required: 'El password es obligatorio',
  minLength: {value: 8, message: 'Mínimo 8 caracteres'},
  validate: {
    lower: p => /[a-z]+/.test(p) || 'Debe contener mínimo una letra minúscula',
    upper: p => /[A-Z]+/.test(p) || 'Debe contener mínimo una letra mayúscula',
    digit: p => /\d+/.test(p) || 'Debe contener mínimo un número',
    special: p =>
      /[!@#$%^&*()\-_=+{};:,<.>]+/.test(p) || 'Debe contener mínimo un caracter especial',
  },
}

export default NewPasswordForm
