import React, {useContext} from 'react'
import PropTypes from 'prop-types'
import {FloatContainerContext} from '../../../helpers/context'

const DropdownItem = ({text, isActive, onClick}) => {
  const {setActive} = useContext(FloatContainerContext)

  const clickFunction = e => {
    e.preventDefault()
    onClick()
    setActive(false)
  }

  return (
    <button
      onClick={e => clickFunction(e)}
      className={`dropdown-item ${isActive && 'is-active'}`}
    >
      {text}
    </button>
  )
}

DropdownItem.propTypes = {
  text: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
}

DropdownItem.defaultProps = {
  isActive: false,
  onClick: () => {},
}

export default DropdownItem
