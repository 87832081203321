import {useState, useEffect, useContext} from 'react'
import { useDispatch } from 'react-redux'
import WelcomeContainer from '../../wrappers/WelcomeContainer'
import FormInput from '../../../ui-kit/components/templates/FormInput'
import {Link, useHistory} from 'react-router-dom'
import {useForm} from 'react-hook-form'
import routes from '../../../config/routes'
import CountrySelect from '../../../ui-kit/components/atoms/CountrySelect'
import { GlobalContext } from "../../../ui-kit/helpers/context"
import Select from "../../../ui-kit/components/atoms/Select"
import { forgotPassword } from '../../../services/user'
import { notifyError, notifySuccessful } from '../../../services/notification'

const ForgottenPasswordForm = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [isLoading, setIsLoading] = useState(false)
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm()

  const {identificationTypes, countryCode} = useContext(GlobalContext)
  const listIdentTypes = identificationTypes?.data?.filter(
    t => t.country === countryCode
  )[0]?.data

  useEffect(() => {
    setValue('identification_type_id', 0)
  }, [countryCode]) // eslint-disable-line

  const submit = data => {
    const payload = {
      identification_number: data.identification_number,
      identification_type_id: Number(data.identification_type_id),
    }

    setIsLoading(true)

    forgotPassword(payload)
    .then(response => {
      dispatch(notifySuccessful(response))

      setIsLoading(false)

      const query = new URLSearchParams()
      query.set('t', payload.identification_type_id)
      query.set('n', payload.identification_number)

      history.push({
        pathname: routes.otp,
        search: `?${query.toString()}`,
      })
    })
    .catch(err => {
      setIsLoading(false)
      dispatch(notifyError(err))
    })
  }

  return (
    <WelcomeContainer
      title="¿Olvidaste tu contraseña?"
      subtitle="No te preocupes, te enviaremos un correo con un código para recuperar tú cuenta."
    >
      <form className="form" onSubmit={handleSubmit(submit)}>
        <div className="grid grid-cols-3">
          <Select
            id="identification_type_id"
            label="Tipo Ident."
            placeholder=" "
            register={register('identification_type_id', {
              required: 'Obligatorio',
            })}
            errorMessage={errors?.identification_type_id?.message}
          >
            {Array.isArray(listIdentTypes) &&
              listIdentTypes.map(ident => (
                <option key={ident.id} value={ident.id}>
                  {ident.abbreviation} - {ident.description}
                </option>
              ))}
          </Select>

          <FormInput
            type="text"
            id="identification_number"
            label="Número de Identificación"
            placeholder="100200300400"
            register={register('identification_number', {
              required: 'La identificación es obligatoria',
            })}
            errorMessage={errors?.identification_number?.message}
            className="cols-2"
          />
        </div>
        <button type="submit" className="button" disabled={isLoading}>
          Recupera tu contraseña
        </button>
        <div>
          <p className="gap-bottom">
            ¿Ya tienes tu cuenta? <Link to={routes.login}>Inicia sesión</Link>
          </p>
          <CountrySelect hasCaption />
        </div>
      </form>
    </WelcomeContainer>
  )
}

export default ForgottenPasswordForm
