import {useState, useCallback, useEffect} from 'react'

function fallbackMathMedia(mediaQuery) {
  if (typeof matchMedia !== 'function') {
    return null
  }
  return matchMedia(mediaQuery)
}

function omitMatchMediaResult(matchMediaResult) {
  if (!matchMediaResult) {
    return null
  }
  return {
    media: matchMediaResult.media,
    matches: matchMediaResult.matches,
  }
}

export const useMediaPredicate = mediaQuery => {
  const result = useState(() => omitMatchMediaResult(fallbackMathMedia(mediaQuery)))
  const setResult = result[1]

  const callback = useCallback(
    matchMediaResult => setResult(omitMatchMediaResult(matchMediaResult)),
    [setResult]
  )

  useEffect(() => {
    const matchMediaResult = fallbackMathMedia(mediaQuery)
    callback(matchMediaResult)
    matchMediaResult.addListener(callback)
    return () => matchMediaResult.removeListener(callback)
  }, [callback, mediaQuery])

  return result[0]
}

export const useMedia = mediaQuery => {
  const result = useMediaPredicate(mediaQuery)
  return (result && result.matches) || false
}
