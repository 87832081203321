import OTPForm from '../components/organism/forms/OTPForm'

const OTP = () => {
  return (
    <div className="background-wrapper">
      <OTPForm />
    </div>
  )
}

export default OTP
