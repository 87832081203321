import EmptyDocument from '../../../ui-kit/static/images/stories/empty-document.svg'

const DocumentEmpty = ({width = 0, height = 0}) => {
  return (
    <section className="center-middle" style={{width: width, height: height}}>
      <div className='center grid gap-1'>
        <div className="center">
          <img  src={EmptyDocument} alt="Empty Document" width="300" height="300"/>
        </div>
        <p className="title title-big">Mis Documentos</p>
        <p className="text text-normal">
          Aquí verás tu reporte, selecciona la opción que desees y da click en el botón generar
        </p>
      </div>
    </section>
  )
}

export default DocumentEmpty
