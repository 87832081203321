import React, {useCallback} from 'react'
import PropTypes from 'prop-types'
import {useDropzone} from 'react-dropzone'
import {isEmpty, len} from '../../helpers/utils'
import {PICTURE_ICON} from '../../helpers/icons/picture'
import Icon from '../atoms/Icon'

const DropImageContainer = props => {
  const {className, label, id, tiny, typeFiles, limitFiles, handleFiles, file} = props

  const onDrop = useCallback(
    acceptedFiles => {
      handleFiles(acceptedFiles)
    },
    [handleFiles]
  )

  const {fileRejections, getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    accept: typeFiles,
    maxFiles: limitFiles,
  })

  const fileRejectionItems = () => {
    if (isEmpty(fileRejections)) return null

    if (len(fileRejections) > limitFiles) {
      return `¡Upps! suelta máximo ${limitFiles} archivo(s)`
    }

    return `¡Upps! solo archivos ${typeFiles} son permitidos`
  }

  return (
    <div
      {...getRootProps()}
      className={`drop-container form-item ${className} ${tiny && 'tiny'} 
      ${isDragActive && 'is-drag'}
      ${file && 'is-uploaded'}
      `}
    >
      <div className="container s-center s-color-light-text cursor-pointer s-pxy-24px s-bg-body-alt normal-radius">
        {file ? (
          <img className="file" src={file} alt="Logo de la empresa" />
        ) : (
          <Icon className="icon" svg={PICTURE_ICON} size="4rem" />
        )}
        <p className="small s-mb-0">
          {file
            ? 'Imagen subida con éxito'
            : isDragActive
            ? 'Suelta aquí tu imagen'
            : fileRejectionItems() || 'Arrastra y suelta tu imagen'}
        </p>
        <input
          data-testid="drop-input-file"
          {...getInputProps()}
          id={id}
          type="file"
          className="hidden"
        />
      </div>
      {label && <label htmlFor={id}>{label}</label>}
    </div>
  )
}

DropImageContainer.propTypes = {
  className: PropTypes.string,
  tiny: PropTypes.bool,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  typeFiles: PropTypes.string,
  limitFiles: PropTypes.number,
  handleFiles: PropTypes.func.isRequired,
}

DropImageContainer.defaultProps = {
  tiny: false,
  className: '',
  typeFiles: 'image/jpeg, image/png',
  limitFiles: 1,
  handleFiles: () => {},
}

export default DropImageContainer
