import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import 'dayjs/locale/es'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import {isNull} from 'lodash'

dayjs.extend(relativeTime).locale('es')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('America/Lima')

const validateDate = date => {
  if (
    !dayjs(date).isValid() ||
    typeof date === 'undefined' ||
    date.includes('0001-01-01')
  ) {
    return false
  } else {
    return true
  }
}

export const getFormattedDate = (date, complete = false, withHour = false) => {
  if (!validateDate(date)) return ''
  try {
    if (complete && !withHour) return dayjs.tz(date).format('DD/MM/YYYY')

    if (withHour && complete) return dayjs.tz(date).format('DD/MM/YYYY hh:mm a')

    if (withHour) return dayjs.tz(date).format('hh:mm a')

    return dayjs.tz(date).fromNow()
  } catch (error) {
    return ''
  }
}

export const getCurrentMonth = () => {
  const month = {
    id: dayjs().get('month'),
    name: dayjs().format('MMMM'),
  }

  return month
}

export const getCurrentMonthAndYear = () => {
  const month = dayjs().get('month')
  const year = dayjs().format('YYYY')
  const currentMonthAndYear = {
    id: Number(`${year}${month}`),
    month,
    year: Number(year),
    monthName: monthsNames[month],
    fullDate: dayjs().format(),
  }

  return currentMonthAndYear
}

const monthsNames = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
]

export const getAviableMonths = (initialDate, currentYear) => {
  const maxMonths = 12
  const defaultStartingMonth = 0
  const initialYear = dayjs(initialDate?.replace('Z', '')).get('year')
  const startingMonth = dayjs(initialDate?.replace('Z', '')).get('month')
  const currentMonth = dayjs().get('month')
  const months = []

  if (currentYear === initialYear) {
    for (let month = startingMonth; month <= currentMonth; month += 1) {
      months.push({
        id: Number(`${currentYear}${month}`),
        month,
        monthName: monthsNames[month],
        year: currentYear,
        fullDate: dayjs(`${currentYear}/${month + 1}`).format(),
      })
    }

    return months
  }

  for (let year = currentYear; year >= initialYear; year -= 1) {
    for (let month = maxMonths - 1; month >= defaultStartingMonth; month -= 1) {
      if (
        (year === initialYear && month >= startingMonth && initialYear !== currentYear) ||
        (year !== initialYear && year !== currentYear) ||
        (year === currentYear && month <= currentMonth)
      ) {
        months.push({
          id: Number(`${year}${month}`),
          month,
          monthName: monthsNames[month],
          year,
          fullDate: dayjs(`${year}/${month + 1}`).format(),
        })
      }
    }
  }

  return months
}

export const getDaysInMonth = month => {
  return dayjs().set('month', month).daysInMonth()
}

export const isSameDayOfMonth = (createdAt, currentDay) => {
  if (typeof createdAt === 'undefined' || isNull(createdAt)) return false
  const created = dayjs(createdAt.replace('Z', '')).date()
  return created === currentDay
}

export const isTodayDate = date => {
  if (typeof date !== 'undefined') {
    return dayjs().diff(date.replace('Z', ''), 'day') === 0
  }
}

export const isTheSameHour = date => {
  if (typeof date !== 'undefined') {
    const date1 = dayjs()
    const date2 = dayjs(date)

    return date1.diff(date2, 'hour') > 4
  }

  return false
}

export const getFullDate = date => {
  if (!validateDate(date)) return ''
  return dayjs(date).format('D MMMM . YYYY').replace('.', 'del') // OUTPUT: 1 Junio del 2020
}

export const getDateWithoutYear = (date, day) => {
  if (!validateDate(date) || typeof day !== 'number') return ''

  const formatted = dayjs(date).set('date', day)
  return formatted.format('dddd, D . MMMM').replace('.', 'de') // OUTPUT: Lunes, 1 de Junio
}

export const getPreviousDate = (month, year) => {
  let previousDate = ''
  const isValidMonth = month >= 0 && month < 12

  if (isNaN(month) || !year || !isValidMonth) return previousDate

  if (month === 0) {
    previousDate = `${monthsNames[11]} ${year - 1}`

    return previousDate
  }

  previousDate = `${monthsNames[month - 1]} ${year}`

  return previousDate
}
export const getDayAndMonthName = date => {
  if (!validateDate(date)) return ''
  return dayjs(date).format('D MMMM').replace(' ', ' de ') // OUTPUT: 19 de Octubre
}

export const getCurrentYear = () => {
  return dayjs().get('year')
}

export const getPreviousMonthName = (currentMonth = 999) => {
  const isValidMonth = currentMonth >= 0 && currentMonth < 12

  if (isNaN(currentMonth) || !isValidMonth) return 'el mes anterior'

  if (currentMonth === 0) {
    return monthsNames[11]
  } else {
    return monthsNames[currentMonth - 1]
  }
}
export const getDayName = (day, isUpperCase) => {
  const days = ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo']

  return isUpperCase ? days[day] : days[day].toLowerCase()
}

export const getMonthName = (month, isUpperCase) => {
  return isUpperCase ? monthsNames[month] : monthsNames[month].toLowerCase()
}

export const getDateWithFormat = (date, format = 'YYYY-MM-DD') => {
  return dayjs
    .tz(date.replace('Z', ''), 'America/Lima')
    .tz(dayjs.tz.guess())
    .format(format)
}

/**
 * RetornarÃ¡ la fecha actual en formato ISO
 * @returns ISOdate  2019-01-25T02:00:00.000Z
 */
export const getCurrentISODate = () => {
  const date = dayjs()
  return dayjs.tz(date, 'America/Lima').format('YYYY-MM-DDTHH:mm:ss')
}
/*
  @items Array de fechas
  @valueToOrder String del nombre del campo por el que se quiera ordenar
*/
export const orderByRecentDate = (items, valueToOrder) => {
  return items.sort((a, b) => b[valueToOrder]?.localeCompare(a[valueToOrder]))
}

export const isSameYear = date => {
  if (typeof date === 'undefined' || isNull(date)) return false
  const year = dayjs(date).get('year')
  return getCurrentYear() === year
}
