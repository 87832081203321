import {useEffect, useContext} from 'react'
import {Link, useHistory} from 'react-router-dom'
import {useForm} from 'react-hook-form'
import {useDispatch} from 'react-redux'
import WelcomeContainer from '../../wrappers/WelcomeContainer'
import FormInput from '../../../ui-kit/components/templates/FormInput'
import PasswordInput from '../../molecules/inputs/PasswordInput'
import routes from '../../../config/routes'
import CountrySelect from '../../../ui-kit/components/atoms/CountrySelect'
import {GlobalContext} from '../../../ui-kit/helpers/context'
import Select from '../../../ui-kit/components/atoms/Select'
import {signIn} from '../../../services/user'

const LoginForm = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm()

  const {identificationTypes, countryCode} = useContext(GlobalContext)
  const listIdentTypes = identificationTypes?.data?.filter(
    t => t.country === countryCode
  )[0]?.data

  useEffect(() => {
    setValue('identification_type_id', 0)
  }, [countryCode]) // eslint-disable-line

  const submit = data => {
    const credentials = {
      ...data,
      identification_type_id: Number(data.identification_type_id),
    }

    signIn(credentials, dispatch, history)
  }

  return (
    <WelcomeContainer title="Bienvenido de nuevo 👋">
      <form className="form" onSubmit={handleSubmit(submit)}>
        <div className="grid grid-cols-3">
          <Select
            id="identification_type_id"
            label="Tipo Ident."
            placeholder=" "
            register={register('identification_type_id', {
              required: 'Obligatorio',
            })}
            errorMessage={errors?.identification_type_id?.message}
          >
            {Array.isArray(listIdentTypes) &&
              listIdentTypes.map(ident => (
                <option key={ident.id} value={ident.id}>
                  {ident.abbreviation} - {ident.description}
                </option>
              ))}
          </Select>

          <FormInput
            type="text"
            id="identification_number"
            label="Número de Identificación"
            placeholder="100200300400"
            register={register('identification_number', {
              required: 'La identificación es obligatoria',
            })}
            errorMessage={errors?.identification_number?.message}
            className="cols-2"
          />
        </div>

        <div className="input-password">
          <PasswordInput
            id="password"
            register={register('password', {
              required: 'El password es obligatorio',
            })}
            errorMessage={errors?.password?.message}
          />

          <div className="right">
            <Link to={routes.recover}>¿Olvidaste tu contraseña?</Link>
          </div>
        </div>
        <button type="submit" className="button">
          Inicia sesión
        </button>
        <CountrySelect hasCaption />
      </form>
    </WelcomeContainer>
  )
}

export default LoginForm
