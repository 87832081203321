import {closeSession} from '../application/auth'
import {Request, RequestWithAuthorization} from './request'
import {actions as userActions} from '../redux/ducks/user'
import {actions as employerActions} from '../redux/ducks/employer'
import routes from '../config/routes'

const RESOURCE = '/self-service/public'
const RESOURCE_WITH_AUTH = '/self-service'
const TOKEN_KEY = process.env.REACT_APP_TOKEN_KEY

export const signIn = (credentials, dispatch, history) => {
  const request = new Request()

  request.post(`${RESOURCE}/login`, credentials).then(response => {
    // it save token in localStorage for persist the token when of page is reloaded
    localStorage.setItem(TOKEN_KEY, response.token)

    dispatch(userActions.signIn(response.employee_relation))
    dispatch(employerActions.set(response.employer))

    history.push(routes.home)
  })
}

export const signOut = () => {
  closeSession()
}

export const forgotPassword = payload => {
  const request = new Request()
  return request.post(`${RESOURCE}/forgot-password`, payload, true)
}

export const changePassword = payload => {
  const request = new Request()
  return request.post(`${RESOURCE}/change-password`, payload, true)
}

export const getUserLoginData = () => dispatch => {
  const request = new RequestWithAuthorization()

  request.get(`${RESOURCE_WITH_AUTH}/employees`).then(response => {
    dispatch(userActions.signIn(response.employee_relation))
    dispatch(employerActions.set(response.employer))
  })
}

// export const updateProfile = (payload, callback, callbackError) => dispatch => {
//   const request = new RequestWithAuthorization()

//   request.put(
//     '/users/me',
//     payload,
//     response => {
//       dispatch(
//         userActions.edit({
//           email: response.email,
//           name: response.name,
//           picture: response.picture,
//         })
//       )
//       callback()
//     },
//     callbackError
//   )
// }
