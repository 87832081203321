import Loader from "../atoms/Loader"

const DocumentLoader = ({width = 0, height = 0}) => {
  return (
    <section className="center-middle" style={{width: width, height: height}}>
      <div className="center" style={{width: "100px"}}>
        <Loader/>
      </div>
    </section>
  )
}

export default DocumentLoader
