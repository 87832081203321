import NetworkInput from '../../molecules/inputs/NetworkInput'
import {FACEBOOK_ICON} from '../../../ui-kit/helpers/icons/facebook'
import {TWITTER_ICON} from '../../../ui-kit/helpers/icons/twitter'
import {INSTAGRAM_ICON} from '../../../ui-kit/helpers/icons/instagram'
import {LINKEDIN_ICON} from '../../../ui-kit/helpers/icons/linkedin'

const SocialNetworksForm = () => {
  return (
    <>
      <NetworkInput
        icon={FACEBOOK_ICON}
        url="facebook.com/"
        nickname="evelyn-castrejon"
      />
      <NetworkInput icon={TWITTER_ICON} url="twitter.com/" nickname="evelyncj" />
      <NetworkInput icon={INSTAGRAM_ICON} url="instagram.com/" nickname="evelyncj98" />
      <NetworkInput
        className="linkedin"
        icon={LINKEDIN_ICON}
        url="linkedin.com/in"
        nickname="evelyn-cast"
      />
    </>
  )
}

export default SocialNetworksForm
