import {useEffect, useState} from 'react'
import ProfileCard from '../components/organism/ProfileCard'
import PublicationForm from '../components/organism/PublicationForm'
import Publication from '../components/organism/Publication'
import {publicationData} from '../helpers/data/publication-data'
import uniqid from 'uniqid'
import {useDispatch, useSelector} from 'react-redux'
import BirthdaySection from '../components/organism/BirthdaySection'
import AnniversarySection from '../components/organism/AnniversarySection'
import {useMedia} from '../ui-kit/hooks/useMedia'
import {mediaFromLg} from '../ui-kit/helpers/media-queries'
import TabsWrapper from '../ui-kit/components/wrappers/TabsWrapper'
import TabItem from '../ui-kit/components/atoms/items/TabItem'
import PanelTemplate from '../ui-kit/components/templates/PanelTemplate'
import FeedEmptyState from '../components/organism/empty/FeedEmptyState'
import DocumentsSection from '../components/organism/DocumentsSection'
import MainHeader from '../components/organism/MainHeader'
import {selectors as userSelectors} from '../redux/ducks/user'
import {isAnyUndefined, isUndefined} from '../ui-kit/helpers/utils'
import {getUserLoginData} from '../services/user'
import FullPageLoader from '../components/molecules/FullPageLoader'
import { getBirthdays } from '../services/employee'

const Home = () => {
  const dispatch = useDispatch()
  const fromLg = useMedia(mediaFromLg)
  const user = useSelector(userSelectors.get)

  const [birthdays, setBirthdays] = useState([])

  useEffect(() => {
    if (isUndefined(user?.employee?.id)) dispatch(getUserLoginData())

    if (!isUndefined(user?.contracts?.[0]?.contract?.employer_id)) {
      getBirthdays(user?.contracts?.[0]?.contract?.employer_id, new Date().getMonth() + 1)
      .then(response => setBirthdays(response))
    }
  }, [user, dispatch])

  return isAnyUndefined(user) ? (
    <FullPageLoader />
  ) : (
    <>
      <MainHeader />
      <div className="home-page wrapper">
        <aside className="aside relative">
          <ProfileCard />
        </aside>
        <main className="main">
          <TabsWrapper defaultActive={0}>
            <div className="tabs-container border">
              <TabItem index={0}>Home</TabItem>
              <TabItem index={1}>Mis Documentos</TabItem>
            </div>
            <PanelTemplate className="feed" index={0}>
              {/* Renderizar este componente si aún no hay publicaciones*/}

              <FeedEmptyState className="content"/>
              {/* <div className="content grid gap-2">
              <PublicationForm />
              {publicationData.data.map(publication => (
                <Publication data={publication} key={uniqid()} />
              ))} 
              </div> */}

              {fromLg && (
                <aside className="aside">
                  <BirthdaySection data={birthdays}/>
                  {/* <AnniversarySection /> */}
                </aside>
              )}
            </PanelTemplate>
            <PanelTemplate index={1}>
              <DocumentsSection />
            </PanelTemplate>
          </TabsWrapper>
        </main>

      </div>
    </>
  )
}

export default Home
