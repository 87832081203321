import Logo from '../atoms/Logo'
import Container from '../../ui-kit/components/wrappers/Container'

const WelcomeContainer = ({title, children, subtitle, graphic}) => {
  return (
    <main className="welcome-container">
      <Container>
        <Logo />
        <h1 className="title-base title">{title}</h1>
        {subtitle && <p className="subtitle">{subtitle}</p>}
        {graphic && <img className="story-graphic" src={graphic} alt={title} />}
        {children}
      </Container>
    </main>
  )
}

export default WelcomeContainer
