import {RequestWithAuthorization} from './request'

const RESOURCE = '/selfservice/employee-relations'

export const generateEmploymentCertificate = employerID => {
  const request = new RequestWithAuthorization()

  return request.getFile(
    `${RESOURCE}/employment-certificate/employer/${employerID}?templateName=default`,  
  )
}

export const getBirthdays = (employerID, month) => {
  const request = new RequestWithAuthorization()

  return request.get(
    `${RESOURCE}/birthdays/${month}?employer-id=${employerID}`,  
  )
}
