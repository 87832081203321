import FormInput from '../../../ui-kit/components/templates/FormInput'
import DropImageContainer from '../../../ui-kit/components/molecules/DropImageContainer'

const PersonalInformationForm = () => {
  return (
    <>
      <DropImageContainer label="Fotografía" id="drop" />
      <FormInput value="Evelyn" label="Primer nombre" isRequired />
      <FormInput label="Segundo nombre" />
      <FormInput value="Castrejón" label="Primer apellido" isRequired />
      <FormInput value="Jiménez" label="Segundo apellido" />
      <FormInput value="Sierra Nevada #8" label="Dirección" isRequired />
      <FormInput value="Xalisco" label="Ciudad" />
      <FormInput value="311124532" label="Teléfono" isRequired />
      <FormInput value="311124532" label="Celular" />
    </>
  )
}

export default PersonalInformationForm
