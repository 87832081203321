import React, {useState, forwardRef, useImperativeHandle, useEffect} from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import Fade from '../wrappers/animations/Fade'
import {useKeyDownEsc} from '../../hooks/UseKeyDownEsc'
import {CROSS_ICON} from '../../helpers/icons/cross'
import Icon from '../atoms/Icon'

const ModalTemplate = forwardRef((props, ref) => {
  const {children, className, isDefaultOpen, isLockDefaultClose} = props
  const [isOpen, setIsOpen] = useState(isDefaultOpen)
  let keyDownEsc = useKeyDownEsc()

  useImperativeHandle(ref, () => {
    return {
      open: () => open(),
      close: () => close(),
    }
  })

  const open = () => {
    setIsOpen(true)
  }

  const close = () => {
    setIsOpen(false)
  }

  const closeDefault = () => {
    if (!isLockDefaultClose) close()
  }

  useEffect(() => {
    if (keyDownEsc && !isLockDefaultClose) close()
  }, [keyDownEsc, isLockDefaultClose])

  return ReactDOM.createPortal(
    <Fade show={isOpen}>
      <div className={`modal-template ${className}`}>
        <div className="wrapper">
          {!isLockDefaultClose && (
            <button className="close-button" onClick={closeDefault}>
              <Icon className="icon" svg={CROSS_ICON} />
            </button>
          )}
          <section className="container">
            <div className="content">{children}</div>
          </section>
        </div>
        <div className="overlay" onClick={closeDefault} />
      </div>
    </Fade>,
    document.getElementById('modal-root')
  )
})

ModalTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isDefaultOpen: PropTypes.bool,
}

ModalTemplate.defaultProps = {
  className: '',
  isDefaultOpen: false,
}

export default ModalTemplate
