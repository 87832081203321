import {useState} from 'react'
import {useDispatch} from 'react-redux'
import WelcomeContainer from '../../wrappers/WelcomeContainer'
import {Link, useHistory, useLocation} from 'react-router-dom'
import {useForm} from 'react-hook-form'
import routes from '../../../config/routes'
import FormInput from '../../../ui-kit/components/templates/FormInput'
import { forgotPassword } from '../../../services/user'
import { notifyError, notifySuccessful } from '../../../services/notification'

const OTPForm = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {search} = useLocation()
  const [isLoading, setIsLoading] = useState(false)

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm()

  const submit = data => {
    const query = new URLSearchParams(search)
    query.set("otp", data.otp)

    history.push({
      pathname: routes.reset,
      search: `?${query.toString()}`,
    })
  }

  const resendOTP = () => {
    if (isLoading) return

    const query = new URLSearchParams(search)

    const payload = {
      identification_number: query.get('n'),
      identification_type_id: Number(query.get('t')),
    }

    setIsLoading(true)

    forgotPassword(payload)
      .then(response => {
        dispatch(notifySuccessful(response))
      })
      .catch(err => {
        dispatch(notifyError(err))
      })
      .finally(setIsLoading(false))
  }

  return (
    <WelcomeContainer
      title="Código de Verificación"
      subtitle="Ingresa el código de verificación que enviamos a tu correo."
    >
      <form className="form" onSubmit={handleSubmit(submit)}>
        <div className="input-password">
          <FormInput
            type="text"
            label="Código Verificación"
            placeholder="OTP"
            register={register('otp', {
              required: 'El código OTP es obligatorio',
            })}
            errorMessage={errors?.otp?.message}
          />
          <div className='right'>
            <span className='link' onClick={resendOTP}>
              Reenviar código OTP
            </span>
          </div>
        </div>

        <button type="submit" className="button">
          Continuar
        </button>
        <p>
          ¿Ya tienes tu cuenta? <Link to={routes.login}>Inicia sesión</Link>
        </p>
      </form>
    </WelcomeContainer>
  )
}

export default OTPForm
