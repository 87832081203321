import {Request} from './request'
import {isNull, isUndefined, isEqual} from '../ui-kit/helpers/utils'
import {CACHE_IDENTIFICATION_TYPES, getLocalCache} from '../application/cache'

export async function getCacheIdentificationTypes() {
  let cache = getLocalCache(CACHE_IDENTIFICATION_TYPES)

  // if the cache doesn't exists or cache hasn't the field updated_at, then get the cache
  if (isNull(cache) || isUndefined(cache.updated_at)) {
    const data = await getCacheByType(CACHE_IDENTIFICATION_TYPES)
    return data
  }

  const lastUpdatedAt = await getCacheUpdatedAtByType(CACHE_IDENTIFICATION_TYPES)

  // if the last updated date is different to the local cache date, then get the last cache
  if (isEqual(lastUpdatedAt, cache.updated_at)) return cache

  const data = await getCacheByType(CACHE_IDENTIFICATION_TYPES)
  return data
}

async function getCacheByType(type) {
  const request = new Request()

  const response = await request.get(`/caches/${type}`)
  if (!response) return

  localStorage.setItem(type, JSON.stringify(response))
  return response
}

async function getCacheUpdatedAtByType(type) {
  const request = new Request()
  const lastUpdatedAt = await request.get(`/caches/updated-at/${type}`)
  
  return lastUpdatedAt
}
