import NewPasswordForm from '../components/organism/forms/NewPasswordForm'

const ResetPassword = () => {
  return (
    <div className="background-wrapper">
      <NewPasswordForm />
    </div>
  )
}

export default ResetPassword
