import {Route, Redirect} from 'react-router-dom'
import {isAuthenticated} from '../../application/auth'
import routes from '../routes'

const Public = ({children, ...rest}) => {
  if (isAuthenticated()) {
    return <Redirect to={routes.home} />
  }

  return <Route {...rest}>{children}</Route>
}

export default Public
