import PropTypes from 'prop-types'
import Icon from '../../ui-kit/components/atoms/Icon'

const SocialNetworkButton = ({url, icon, title}) => {
  return (
    <a className="social-network-button" href={url} target="_blank" title={title}>
      <Icon svg={icon} />
    </a>
  )
}

SocialNetworkButton.propTypes = {
  url: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
}

export default SocialNetworkButton
