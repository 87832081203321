import PropTypes from 'prop-types'
import PublicationButton from '../../templates/PublicationButton'
import {COMMENT_ICON} from '../../../ui-kit/helpers/icons/comment'

const CommentButton = ({tiny, text, onClick}) => {
  return (
    <PublicationButton onClick={onClick} tiny={tiny} text={text} icon={COMMENT_ICON} />
  )
}

CommentButton.propTypes = {
  text: PropTypes.string,
}

CommentButton.defaultProps = {
  text: 'Comentar',
}

export default CommentButton
