import React from 'react'
import PropTypes from 'prop-types'

const Container = ({className, children}) => {
  return <section className={`n-container ${className}`}>{children}</section>
}

Container.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

Container.defaultProps = {
  className: '',
}

export default Container
