import React, {useRef, useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {useClickOutside} from '../../hooks/useClickOutside'
import {useKeyDownEsc} from '../../hooks/UseKeyDownEsc'
import {FloatContainerContext} from '../../helpers/context'
import Fade from './animations/Fade'

const FloatContainer = ({children, container}) => {
  // Estado para mostrar el contenedor flotante
  const [active, setActive] = useState(false)
  // Referencia activador
  const trigger = useRef()
  // Referencia contenido
  const content = useRef()
  // Detectamos si se dio click fuera del contenido
  const clickOutsideContent = useClickOutside(content)
  // Detectamos si se dio clck fuera del activador
  const clickOutsideTrigger = useClickOutside(trigger)
  // Detectamos la tecla de ESC
  let keyDownEsc = useKeyDownEsc()

  // Activamos o desactivamos el contenido
  const handleToggleActive = () => {
    setActive(!active)
  }

  // Validamos si se dio click fuera del contenido y del activador o presionó la tecla ESC para cerrar la ventana
  useEffect(() => {
    if ((clickOutsideContent && clickOutsideTrigger) || keyDownEsc) {
      setActive(false)
    }
  }, [clickOutsideContent, clickOutsideTrigger, keyDownEsc])

  return (
    <FloatContainerContext.Provider value={{setActive}}>
      <div ref={trigger} onClick={handleToggleActive}>
        {children[0]}
      </div>
      <Fade show={active} className={container.className}>
        <div ref={content}>{children[1]}</div>
      </Fade>
    </FloatContainerContext.Provider>
  )
}

FloatContainer.propTypes = {
  container: PropTypes.shape({
    className: PropTypes.string,
  }),
}

FloatContainer.defaultProps = {
  container: {
    className: '',
  },
}

export default FloatContainer
