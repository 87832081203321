import React, {useContext} from 'react'
import PropTypes from 'prop-types'
import {TabsWrapperContext} from '../../helpers/context'

const TabTemplate = ({index, children, className, disabled}) => {
  const {tabActive, setTabActive} = useContext(TabsWrapperContext)
  const isActive = tabActive === index
  const setThisTabActive = () => !disabled && setTabActive(index)

  return (
    <div
      onClick={setThisTabActive}
      className={`tab-template ${isActive ? 'active' : 'inactive'} ${className}`}
    >
      {children}
    </div>
  )
}

TabTemplate.propTypes = {
  index: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
}

TabTemplate.defaultProps = {
  className: '',
  disabled: false,
}

export default TabTemplate
