import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {TabsWrapperContext} from '../../helpers/context'

const TabsWrapper = ({defaultActive, children}) => {
  const [tabActive, setTabActive] = useState(defaultActive)

  return (
    <TabsWrapperContext.Provider
      value={{
        tabActive,
        setTabActive,
      }}
    >
      {children}
    </TabsWrapperContext.Provider>
  )
}

TabsWrapper.propTypes = {
  defaultActive: PropTypes.number,
}

TabsWrapper.defaultProps = {
  defaultActive: 0,
}

export default TabsWrapper
