import { useState, useEffect } from "react"
import Select from "../../ui-kit/components/atoms/Select"
import { generatePayslipFile, getPayslipProcesses } from "../../services/payslip"
import { isEmpty } from "../../ui-kit/helpers/utils"
import {SAVE_ICON} from '../../ui-kit/helpers/icons/save'
import Icon from "../../ui-kit/components/atoms/Icon"

const PayslipOptions = ({hasFile = false, onDownload, setFile, contractID}) => {  
  const [isLoading, setIsLoading] = useState(false)
  const [processes, setProcesses] = useState([])
  const [processHash, setProcessHash] = useState("")

  useEffect(() => {
    if (!contractID) return

    setIsLoading(true)

    getPayslipProcesses(contractID)
    .then(response => setProcesses(response))
    .catch(err => console.error("when get the payslip processes", err))
    .finally(setIsLoading(false))
  }, [contractID])

  function handleChangeProcessHash(e) {
    setProcessHash(e.target.value)
  }

  function handleGenerate() {
    setIsLoading(true)

    generatePayslipFile(contractID, processHash)
    .then(file => setFile(file.data))
    .finally(setIsLoading(false))
  }

  const handleOnDownload = () => onDownload()

  return (
    <>
      <Select
        id="process_hash"
        placeholder="Selecciona un periodo"
        classNameSelect="small"
        onChange={handleChangeProcessHash}
      >
        {Array.isArray(processes) &&
          processes.map(process => (
            <option key={process.process_hash} value={process.process_hash}>
              {process.process_description}
            </option>
          ))}
      </Select>

      <button className="button small" onClick={handleGenerate} disabled={isLoading || isEmpty(processHash)}>
        Generar
      </button>

      {hasFile && (
        <span
          title="Descargar documento"
          className="action-button blue"
          onClick={handleOnDownload}
        >
          <Icon className="icon" svg={SAVE_ICON} />
        </span>
      )}
    </>
  )
}

export default PayslipOptions
