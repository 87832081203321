import { useState } from "react"
import Icon from "../../ui-kit/components/atoms/Icon"
import { generateEmploymentCertificate } from "../../services/employee"
import {SAVE_ICON} from '../../ui-kit/helpers/icons/save'

const LaborCertificateOptions = ({hasFile = false, onDownload, setFile, employerID = 0}) => {
   const [isLoading, setIsLoading] = useState(false)

  function handleGenerate() {
    setIsLoading(true)

    generateEmploymentCertificate(employerID)
    .then(file => setFile(file.data))
    .finally(setIsLoading(false))
  }

  const handleOnDownload = () => onDownload()

  return (
    <>
      {hasFile ? (
      <span
        title="Descargar documento"
        className="action-button blue"
        onClick={handleOnDownload}
      >
        <Icon className="icon" svg={SAVE_ICON} />
      </span>
      ): <button className="button small" onClick={handleGenerate} disabled={isLoading}>Generar</button> }
    </>
  )
}

export default LaborCertificateOptions
