import {Route, Redirect} from 'react-router-dom'
import {isAuthenticated} from '../../application/auth'
import routes from '../routes'

const Private = ({children, ...rest}) => {
  if (!isAuthenticated()) {
    return <Redirect to={routes.login} />
  }

  return <Route {...rest}>{children}</Route>
}

export default Private
