import ForgottenPasswordForm from '../components/organism/forms/ForgottenPasswordForm'

const RecoverPassword = () => {
  return (
    <div className="background-wrapper">
      <ForgottenPasswordForm />
    </div>
  )
}

export default RecoverPassword
