import UserAvatar from '../../ui-kit/components/atoms/UserAvatar'
import NavItem from '../../ui-kit/components/atoms/items/NavItem'
import FloatContainer from '../../ui-kit/components/wrappers/FloatContainer'
import {USER_ICON} from '../../ui-kit/helpers/icons/user'
import {EXIT_ICON} from '../../ui-kit/helpers/icons/exit'
import {useSelector} from 'react-redux'
import {useMedia} from '../../ui-kit/hooks/useMedia'
import {mediaFromM} from '../../ui-kit/helpers/media-queries'
import routes from '../../config/routes'
import {selectors as userSelectors} from '../../redux/ducks/user'
import {capitalize} from '../../ui-kit/helpers/string-format'
import {signOut} from '../../services/user'

const HeaderUserAvatar = () => {
  const user = useSelector(userSelectors.get)

  const fromM = useMedia(mediaFromM)
  return (
    <div className="header-user-avatar">
      <FloatContainer>
        <div className="user">
          <UserAvatar
            picture={user?.employee?.thumbnail}
            gender={user?.employee?.gender}
          />
          {fromM && (
            <p>
              {capitalize(
                [user?.employee?.first_name, user?.employee?.last_name].join(' ')
              )}
            </p>
          )}
        </div>
        <div className="float-container right-side">
          <ul className="list-container">
            <NavItem
              isInDropdown
              url={routes.profile}
              icon={USER_ICON}
              text="Editar perfil"
            />
            <NavItem
              isInDropdown
              onClick={signOut}
              className="logout"
              icon={EXIT_ICON}
              text="Cerrar sesión"
            />
          </ul>
        </div>
      </FloatContainer>
    </div>
  )
}

export default HeaderUserAvatar
