import FloatContainer from '../wrappers/FloatContainer'
import Icon from './Icon'
import {DOWN_ICON} from '../../helpers/icons/down'

const DropdownSelect = ({children, selected, className}) => {
  return (
    <div className={`dropdown-select ${className}`}>
      <FloatContainer>
        <div className="select-template">
          <p>{selected}</p>
          <Icon className="caret" size="1em" svg={DOWN_ICON} />
        </div>
        <div className="float-container">{children}</div>
      </FloatContainer>
    </div>
  )
}

export default DropdownSelect
