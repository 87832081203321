import Logo from '../atoms/Logo'
import HeaderUserAvatar from '../molecules/HeaderUserAvatar'
import Notifications from './Notifications'
import {Link} from 'react-router-dom'

const MainHeader = () => {
  return (
    <header className="main-header">
      <Link to="/">
        <Logo />
      </Link>
      <div className="right">
        {/* <Notifications /> */}
        <HeaderUserAvatar />
      </div>
    </header>
  )
}

export default MainHeader
