import { useContext } from 'react'
import FloatContainer from '../wrappers/FloatContainer'
import Icon from './Icon'
import {DOWN_ICON} from '../../helpers/icons/down'
import CountrySelectItem from './items/CountrySelectItem'
import { CACHE_COUNTRY_CODE } from '../../../application/cache'
import { COL, PER, WRD } from '../../../helpers/country'
import { GlobalContext } from '../../helpers/context'

class Flag {
  constructor(code, vb, content) {
    this.vb = vb
    this.content = content
    this.code = code
  }

  getSVG() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1.8em"
        height="1.8em"
        viewBox={this.vb}
      >
        {this.content}
      </svg>
    )
  }

  getCode() {
    return this.code
  }
}

const FLAGS_MAP = {
  [COL]: new Flag(
    COL,
    '0 0 512 342',
    (
      <>
        <path d="M512 0H0V341.326H512V0Z" fill="#FFDA44" />
        <path d="M512 257.759H0V341.326H512V257.759Z" fill="#D80027" />
        <path d="M512 170.663H0V257.759H512V170.663Z" fill="#0052B4" />
      </>
    )
  ),
  [PER]: new Flag(
    PER,
    '0 0 512 342',
    (
      <>
        <path d="M512 0H0V341.326H512V0Z" fill="#F0F0F0" />
        <path d="M170.663 0H0V341.337H170.663V0Z" fill="#D80027" />
        <path d="M512 0H341.337V341.337H512V0Z" fill="#D80027" />
      </>
    )
  ),
  [WRD]: new Flag(
    WRD,
    '0 0 20 20',
    (
      <>
        <path d="M20 3.3335H0V16.6665H20V3.3335Z" fill="#3B9DFF" />
        <path d="M20 7.10132H0V12.8987H20V7.10132Z" fill="#3B9DFF" />
        <path d="M20 3.3335H0V7.10131H20V3.3335Z" fill="#3B9DFF" />
        <path
          d="M13.5356 6.46447C14.4799 7.40885 15 8.66447 15 10C15 11.3356 14.4799 12.5912 13.5356 13.5356C12.5911 14.4799 11.3356 15 10 15C8.66447 15 7.40886 14.4799 6.46447 13.5356C5.52009 12.5912 5 11.3356 5 10C5 8.66447 5.52006 7.40885 6.46444 6.46447C7.40886 5.52009 8.66447 5 10 5C11.3356 5 12.5911 5.52009 13.5356 6.46447ZM13.0936 13.0936C13.7094 12.4778 14.1189 11.7107 14.2877 10.8762C14.1561 11.0699 14.0302 11.1418 13.9523 10.7082C13.872 10.0013 13.2227 10.4528 12.8144 10.2017C12.3847 10.4913 11.4188 9.63863 11.583 10.6004C11.8362 11.0342 12.9502 10.0198 12.395 10.9377C12.0407 11.5785 11.0996 12.9976 11.2221 13.7332C11.2375 14.8049 10.127 13.9567 9.74439 13.6012C9.48702 12.8891 9.65667 11.6444 8.98365 11.2956C8.25319 11.2639 7.6262 11.1975 7.3431 10.3809C7.17272 9.79664 7.52439 8.92687 8.1505 8.7926C9.06702 8.21678 9.39442 9.46696 10.2539 9.4902C10.5208 9.21097 11.2482 9.12216 11.3086 8.80904C10.7446 8.70953 12.0241 8.33485 11.2546 8.12173C10.83 8.17167 10.5565 8.5619 10.7822 8.89281C9.95956 9.08462 9.93322 7.70233 9.14243 8.13833C9.12234 8.82769 7.85123 8.36181 8.70263 8.22205C8.99518 8.09424 8.2255 7.72385 8.64132 7.79116C8.84558 7.78006 9.53322 7.53909 9.34713 7.37709C9.73003 7.13939 10.0518 7.94632 10.4266 7.35871C10.6972 6.90689 10.3131 6.82348 9.97398 7.05249C9.78275 6.8384 10.3116 6.37597 10.778 6.17615C10.9335 6.10955 11.0819 6.07327 11.1954 6.08354C11.4304 6.35495 11.8649 6.40196 11.8876 6.0509C11.3058 5.77226 10.6642 5.62505 10 5.62505C9.04667 5.62505 8.13994 5.92801 7.38959 6.48823C7.59123 6.58061 7.7057 6.69564 7.51143 6.84268C7.3605 7.29243 6.74807 7.89616 6.21044 7.81068C5.93126 8.29208 5.74743 8.82246 5.66883 9.37833C6.11912 9.52731 6.22298 9.82219 6.12623 9.92082C5.89678 10.1209 5.75573 10.4045 5.6831 10.715C5.82968 11.6118 6.2512 12.4384 6.90643 13.0936C7.73275 13.9199 8.83137 14.375 10 14.375C11.1686 14.375 12.2673 13.9199 13.0936 13.0936Z"
          fill="white"
        />
      </>
    )
  ),
}

const FLAGS = [FLAGS_MAP.COL, FLAGS_MAP.PER, FLAGS_MAP.WRD]

const CountrySelect = ({hasCaption = false, className}) => {
  const {countryCode, setCountryCode} = useContext(GlobalContext)

  const handleOnSelect = code => {
    localStorage.setItem(CACHE_COUNTRY_CODE, code)
    setCountryCode(code)
  }

  return (
    <div className={hasCaption ? 'country-select has-caption' : 'country-select'}>
      {hasCaption && <p className="caption">Estás usando nomineros en:</p>}
      <div className={`dropdown-select ${className}`}>
        <FloatContainer>
          <div className="select-template">
            <span className="content">{FLAGS_MAP[countryCode].getSVG()}</span>
            <Icon className="caret" size="1em" svg={DOWN_ICON} />
          </div>
          <div className="float-container">
            {FLAGS.map(f => (
              <CountrySelectItem
                key={f.code}
                flag={f.getSVG()}
                isActive={f.code === countryCode}
                onClick={() => handleOnSelect(f.code)}
              />
            ))}
          </div>
        </FloatContainer>
      </div>
    </div>
  )
}

export default CountrySelect
