import React from 'react'
import PropTypes from 'prop-types'
import TabTemplate from '../../templates/TabTemplate'

const TabItem = props => {
  const {children, index, className} = props
  return (
    <TabTemplate className={`tab-item ${className}`} index={index}>
      {children}
    </TabTemplate>
  )
}

TabItem.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  className: PropTypes.string,
}

TabItem.defaultProps = {
  className: '',
}

export default TabItem
