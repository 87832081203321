import {createDuck} from 'redux-duck'

const duck = createDuck('app/employer')

// Define action types
export const SET_EMPLOYER = duck.defineType('SET_EMPLOYERS')

// Define action creators
export const actions = {
  set: duck.createAction(SET_EMPLOYER),
}

// Define selectors
export const selectors = {
  get: store => store.employerReducer,
}

const initialState = {}

// Define reducer
export default duck.createReducer(
  {
    [SET_EMPLOYER]: (state, {payload}) => ({
      ...state,
      ...payload,
    }),
  },
  initialState
)
