import {useSelector} from 'react-redux'
import UserAvatar from '../../ui-kit/components/atoms/UserAvatar'
import {TWITTER_ICON} from '../../ui-kit/helpers/icons/twitter'
import {FACEBOOK_ICON} from '../../ui-kit/helpers/icons/facebook'
import {LINKEDIN_ICON} from '../../ui-kit/helpers/icons/linkedin'
import SocialNetworkButton from '../atoms/SocialNetworkButton'
import {INSTAGRAM_ICON} from '../../ui-kit/helpers/icons/instagram'
import {selectors as userSelectors} from '../../redux/ducks/user'
import {capitalize} from '../../ui-kit/helpers/string-format'

const ProfileCard = () => {
  const user = useSelector(userSelectors.get)

  return (
    <article className="profile-card n-container">
      <header className="header">
        <UserAvatar picture={user?.employee?.thumbnail} gender={user?.employee?.gender} />
      </header>
      <div className="content">
        <p className="title title-base">
          {capitalize(
            [
              user?.employee?.first_name,
              user?.employee?.middle_name,
              user?.employee?.last_name,
              user?.employee?.surname,
            ].join(' ')
          )}
        </p>
        <p>{user?.contracts?.[0]?.employer_job}</p>
        <div className="social-network-container">
          <SocialNetworkButton
            title="Facebook"
            url={
              user?.employee?.social_networks?.facebook
                ? `https://facebook.com/${user?.employee?.social_networks?.facebook}`
                : 'https://facebook.com'
            }
            icon={FACEBOOK_ICON}
          />
          <SocialNetworkButton
            title="Twitter"
            url={
              user?.employee?.social_networks?.twitter
                ? `https://twitter.com/${user?.employee?.social_networks?.twitter}`
                : 'https://twitter.com'
            }
            icon={TWITTER_ICON}
          />
          <SocialNetworkButton
            title="Linkedin"
            url={
              user?.employee?.social_networks?.linked_in
                ? `https://www.linkedin.com/in/${user?.employee?.social_networks?.linked_in}`
                : 'https://www.linkedin.com/in'
            }
            icon={LINKEDIN_ICON}
          />
          <SocialNetworkButton
            title="Instagram"
            url={
              user?.employee?.social_networks?.instagram
                ? `https://www.instagram.com/${user?.employee?.social_networks?.instagram}`
                : 'https://www.instagram.com'
            }
            icon={INSTAGRAM_ICON}
          />
        </div>
      </div>
    </article>
  )
}

export default ProfileCard
