import {combineReducers} from 'redux'
import notificationReducer from './ducks/notification'
import notificationUsersReducer from './ducks/notificationUsers'
import userReducer, {SIGN_OUT} from './ducks/user'
import employerReducer from './ducks/employer'

const reducers = combineReducers({
  notificationReducer,
  userReducer,
  employerReducer,
  notificationUsersReducer,
})

const rootReducer = (state, action) => {
  if (action.type === SIGN_OUT) {
    state = undefined
  }

  return reducers(state, action)
}

export default rootReducer
