import NewsGuy from '../../../ui-kit/static/images/stories/news-guy.svg'

const FeedEmptyState = ({className}) => {
  return (
    <section className={`feed-empty-state n-container ${className}`}>
      <div className='center grid gap-1'>
        <div className="center">
          <img src={NewsGuy} alt="Chico con megáfono" width="300" height="300"/>
        </div>
        <p className="title title-big">¡Muy Pronto!</p>
        <p className="text text-normal">
          Este será un espacio en donde verás anuncios de tu empresa.
        </p>
      </div>
    </section>
  )
}

export default FeedEmptyState
