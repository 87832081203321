import React, {useContext} from 'react'
import {FloatContainerContext} from '../../../helpers/context'

const CountrySelectItem = ({flag, isActive, onClick}) => {
  const {setActive} = useContext(FloatContainerContext)

  const clickFunction = e => {
    e.preventDefault()
    onClick()
    setActive(false)
  }

  return (
    <div
      onClick={e => clickFunction(e)}
      className={`dropdown-item ${isActive && 'is-active'}`}
    >
      {flag}
    </div>
  )
}

export default CountrySelectItem
