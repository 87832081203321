import Container from '../ui-kit/components/wrappers/Container'
import TabsWrapper from '../ui-kit/components/wrappers/TabsWrapper'
import TabItem from '../ui-kit/components/atoms/items/TabItem'
import PanelTemplate from '../ui-kit/components/templates/PanelTemplate'
import PersonalInformationForm from '../components/organism/forms/PersonalInformationForm'
import SocialNetworksForm from '../components/organism/forms/SocialNetworksForm'

const Profile = () => {
  return (
    <main className="config-page wrapper">
      <Container className="content-config">
        <h1 className="title title-big">Editar perfil</h1>
        <TabsWrapper>
          <div className="tabs-container">
            <TabItem index={0}>Datos personales</TabItem>
            <TabItem index={1}>Redes sociales</TabItem>
          </div>
          <form className="form-config">
            <PanelTemplate className="form-grid" onlyHidden index={0}>
              <PersonalInformationForm />
            </PanelTemplate>
            <PanelTemplate className="form-grid networks-panel" onlyHidden index={1}>
              <SocialNetworksForm />
            </PanelTemplate>
            <footer className="footer-config">
              <p className="copy-form">
                Los campos con <span className="accent">*</span> son obligatorios
              </p>
              <div className="buttons-container">
                <button className="button cancel">Cancelar</button>
                <button className="button" disabled>
                  Guardar
                </button>
              </div>
            </footer>
          </form>
        </TabsWrapper>
      </Container>
    </main>
  )
}

export default Profile
