import {RequestWithAuthorization} from './request'

const RESOURCE = '/selfservice/payslips'

export const getPayslipProcesses = contractID => {
  const request = new RequestWithAuthorization()

  return request.get(`${RESOURCE}/processes?contract-id=${contractID}`, true)
}

export const generatePayslipFile = (contractID, processHash) => {
  const request = new RequestWithAuthorization()

  return request.generateFile(
    `${RESOURCE}/employees/generate-report?contract-id=${contractID}&process-hash=${processHash}`, 
    null, 
  )
}
