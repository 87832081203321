// import {decode} from 'jsonwebtoken'

const TOKEN_KEY = process.env.REACT_APP_TOKEN_KEY

// TODO
// TODO no esta funcionando el paquete "jsonwebtoken" investigar como resolver ese problema
// TODO parece que es un problema del webpack, sino lo logro solucionar buscar otro paquete

export const isAuthenticated = () => {
  const token = localStorage.getItem(TOKEN_KEY)

  if (token === null) {
    removeSession()
    return false
  }

  return true

  // const tokenDecoded = decode(token)
  // if (tokenDecoded === null) {
  //   removeSession()
  //   return false
  // }

  // if (tokenDecoded.exp !== null && Number(`${tokenDecoded.exp}000`) <= Date.now()) {
  //   removeSession()
  //   return false
  // }

  // return (
  //   tokenDecoded.user_id !== null &&
  //   tokenDecoded.role_id !== null &&
  //   tokenDecoded.email !== null &&
  //   tokenDecoded.iss !== null &&
  //   Number(tokenDecoded.exp) > 0
  // )
}

// removeSession remueve la sesión en la app
export const removeSession = () => {
  localStorage.removeItem(TOKEN_KEY)
}

// closeSession cierra la sesión del usuario
export const closeSession = () => {
  removeSession()
  window.location.reload()
}
