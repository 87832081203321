export const CACHE_IDENTIFICATION_TYPES = 'ALL:IDENTIFICATION_TYPES'
export const CACHE_COUNTRY_CODE = 'COUNTRY_CODE'

export const getLocalCache = cacheName => {
  let cache = localStorage.getItem(cacheName)

  try {
    cache = JSON.parse(cache)
  } catch (e) {
    return null
  }

  return cache
}

