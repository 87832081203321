import FormInput from '../../../ui-kit/components/templates/FormInput'
import Icon from '../../../ui-kit/components/atoms/Icon'

const NetworkInput = ({url, nickname, icon, className}) => {
  return (
    <div className={`network-input ${className}`}>
      <Icon className="icon" svg={icon} />
      <p>{url}</p>
      <FormInput value={nickname} />
    </div>
  )
}

export default NetworkInput
