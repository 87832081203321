import PropTypes from 'prop-types'
import Icon from '../atoms/Icon'

const IconText = ({text, icon, className}) => {
  return (
    <div className={`icon-text ${className}`}>
      <Icon className="icon" svg={icon} />
      <p className="text">{text}</p>
    </div>
  )
}

IconText.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.node,
  className: PropTypes.string,
}

IconText.defaultProps = {
  text: '',
  icon: null,
  className: '',
}

export default IconText
