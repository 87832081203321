import React, {useState} from 'react'
import {GlobalContext} from '../../helpers/context'
import { COL } from '../../../helpers/country'
import { CACHE_COUNTRY_CODE } from '../../../application/cache'

const GlobalWrapper = ({children}) => {
  const [identificationTypes, setIdentificationTypes] = useState(null)
  const [countryCode, setCountryCode] = useState(localStorage.getItem(CACHE_COUNTRY_CODE) || COL)

  return (
    <GlobalContext.Provider
      value={{
        identificationTypes,
        setIdentificationTypes,
        countryCode,
        setCountryCode,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export default GlobalWrapper
