import React from 'react'
import Animation from '../../templates/Animation'

const Fade = ({children, show, className, entryDelay}) => {
  return (
    <Animation
      className={className}
      show={show}
      animationOut="fade-out"
      animationIn="fade-in"
      entryDelay={entryDelay}
    >
      {children}
    </Animation>
  )
}

export default Fade
