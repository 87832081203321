import React from 'react'
import PropTypes from 'prop-types'
import FormInput from '../../../ui-kit/components/templates/FormInput'

const PasswordInput = ({label, register, errorMessage, ...rest}) => {
  return (
    <FormInput
      label={label || 'Contraseña'}
      id="login-password"
      name="password"
      type="password"
      placeholder="••••••••••"
      errorMessage={errorMessage}
      register={register}
      {...rest}
    />
  )
}

PasswordInput.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
}

export default PasswordInput
