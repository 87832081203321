import Logotype from '../../static/images/logo.svg'

const Logo = () => {
  return (
    <div className="company-logo">
      <img className="svg" src={Logotype} alt="Logotipo de la empreas" />
    </div>
  )
}

export default Logo
