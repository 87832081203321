import Icon from '../../ui-kit/components/atoms/Icon'

const PublicationButton = ({className, text, icon, tiny, onClick, isProud}) => {
  return (
    <button
      onClick={onClick}
      className={`publication-button ${className} ${tiny && 'tiny'}`}
    >
      {!tiny &&
        (isProud ? (
          <img className="icon brand" src={icon} width={14} height={14} alt="Isotype" />
        ) : (
          <Icon className="icon" size="20px" svg={icon} />
        ))}
      {text}
    </button>
  )
}

PublicationButton.propTypes = {}

export default PublicationButton
